import React, { useEffect, useState, useRef } from 'react'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import slugify from 'slugify'
import styled, { keyframes } from 'styled-components'

import TitleH1 from 'src/components/global/texts/TitleH1.js'
import BackLink from 'src/components/global/buttons/BackLink.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import SmallTitle from 'src/components/global/texts/SmallTitle.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import Seo from 'src/components/global/Seo.js'

import colors from 'src/assets/styles/colors'
import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import distances from '../assets/styles/distances'
import arrow from 'src/assets/svg/arrow-dow-artist.svg'
import gold from 'src/assets/svg/Zlota.svg'
import platinum from 'src/assets/svg/Platynowa.svg'
import diamond from 'src/assets/svg/Diamentowa.svg'

const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;
`

const Header = styled.header`
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  padding-bottom: ${distances.mBottomPage}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-bottom: ${distances.mBottomPage / 2}rem;
  }
`

const StyledImg = styled(Img)`
  width: 100%;
  margin-bottom: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 25rem;
    margin-bottom: 0;
  }
`

const AlbumName = styled(TitleH1)`
  margin-top: ${distances.mFromTop}rem;
  margin-bottom: 20rem;
  color: ${colors.mainColor};
  margin-left: 20rem;
  word-wrap: break-word;

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    margin-top: ${distances.mFromTop / 2}rem;
  }
`
const MainCol = styled.div`
  width: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    ${AlbumName} {
      display: none;
    }
    ${SmallTitle} {
      display: none;
    }
  }
`

const CoverCol = styled.div`
  width: 33.33%;
  margin-top: ${distances.mFromTop}rem;

  position: sticky;
  top: ${distances.mFromTop}rem;
  ${AlbumName} {
    display: none;
  }
  ${SmallTitle} {
    display: none;
  }
  align-self: flex-start;
  &.mobile {
    display: none;
  }
  button:first-of-type {
    display: none;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    position: relative;
    top: 0;
    margin-top: calc(${distances.mFromTop}rem + 35rem);

    display: flex;
    flex-direction: column;
    ${AlbumName} {
      display: block;
    }
    ${SmallTitle} {
      display: block;
    }
    button:first-of-type {
      display: flex;
      align-self: flex-end;
    }
    button:last-of-type {
      display: none;
    }
  }
`

const InformationColumn = styled.div`
  width: calc(50% - 20rem);
  margin-left: 50%;
  margin-top: 50rem;
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(100% - 40rem);
    margin-left: 20rem;
  }
`

const DateOfUpdate = styled.div`
  position: absolute;
  font-size: 9rem;
  color: ${colors.black};
  padding: 3rem 5rem;
  background-color: ${colors.white};
  bottom: 0;
  right: 0;
  transform: translate(0%, 80%) rotate(3deg);
  font-weight: 700;
  line-height: 1.2;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  opacity: 0;
  text-transform: uppercase;
  @media (max-width: ${mediaQuery.tablet}) {
    transform: translate(0%, 20%) rotate(-3deg);
    opacity: 1;
  }
`

const AwwardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30rem;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    justify-content: space-around;
  }
  :hover {
    ${DateOfUpdate} {
      transform: translate(0%, 20%) rotate(-3deg);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      opacity: 1;
    }
  }
`

const AwardWrapper = styled.div`
  margin-right: 20rem;
  margin-bottom: 20rem;
  position: relative;
  span {
    position: absolute;
    color: ${colors.white};
    font-size: 8rem;
    font-weight: 700;
    bottom: 10rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 5rem;
    margin-right: 5rem;
  }
`

const InformationRow = styled.div`
  margin-top: 30rem;
`

const InformationTitle = styled.h4`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 10rem;
`

const InformationServicesPos = styled.span`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
  line-height: 1;

  span {
    color: ${colors.mainColor};
    font-size: ${typographySizes.s}rem;
    font-weight: 400;
    display: inline-block;
    margin: 0 10rem;
  }
  :last-of-type {
    span {
      display: none;
    }
  }
`

const InformationLink = styled(Link)`
  position: relative;
  color: ${colors.white};
  text-transform: uppercase;
  font-size: ${typographySizes.s}rem;
  font-weight: 400;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  line-height: 1.5;

  span {
    color: ${colors.mainColor};
    font-size: ${typographySizes.s}rem;
    font-weight: 400;
    display: inline-block;
    margin: 0 10rem;
  }
  :last-of-type {
    span {
      display: none;
    }
  }
  :hover {
    color: ${colors.mainColor};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const StyledIframe = styled.iframe`
  margin-top: 50rem;
  margin-left: 20rem;
  margin-right: 20rem;
  width: calc(100% - 40rem);
`

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  margin-left: 20rem;
  margin-right: 20rem;
  width: calc(100% - 40rem);
  margin-top: 50rem;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const SideCol = styled.div`
  width: 16.66%;

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;

  align-self: flex-start;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`
const showSideCol = keyframes`
  from {

    opacity: 0
  }

  to {

    opacity: 1;

  }
  `

const showSideText = keyframes`
from {
  transform: translateY(-20rem);

  opacity: 0
}

to {
  transform: translateY(0rem);

  opacity: 1;

}
`

const SideColWrap = styled.div`
  opacity: 0;
  animation: ${showSideCol} 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
`

const arrowBounce = keyframes`
  from {

    transform: translateY(-10rem);
  }

  to {
    transform: translateY(10rem);

  }
`

const ArrowWrapper = styled.div`
  transform: translateY(-10rem);

  animation: ${arrowBounce} 1s cubic-bezier(0.65, 0, 0.35, 1) infinite alternate;
`

const Arrow = styled.img`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.bottom {
    transform: rotate(-180deg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`
const SideColTextWrapp = styled.div`
  overflow: hidden;
  max-width: 150rem;
  margin-top: 20rem;
  height: 28rem;
  opacity: 0;
  animation: ${showSideText} 0.5s 1s cubic-bezier(0.65, 0, 0.35, 1) forwards;

  p {
    font-size: ${typographySizes.s}rem;
    color: ${colors.white};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 2rem;
  }
  p.bottom {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(calc(-100% - 2rem));
  }
`
const RecruitmentLayout = ({ data }) => {
  const [onBottom, setOnBottom] = useState(false)

  const isBrowser = typeof window !== 'undefined'
  let sideCol = useRef(null)

  useEffect(() => {
    if (isBrowser) {
      if (window.innerWidth > 900) {
        window.onscroll = function () {
          const bounds = sideCol?.current.getBoundingClientRect()

          bounds?.top < 0 ? setOnBottom(true) : setOnBottom(false)
        }
      }
    }
  }, [])
  return (
    <>
      <Seo
        title={
          data.strapiAlbums.SEO
            ? data.strapiAlbums.SEO.Title
            : data.strapiAlbums.Name
        }
        description={
          data.strapiAlbums.SEO ? data.strapiAlbums.SEO.Description : null
        }
        image={
          data.strapiAlbums.SEO
            ? data.strapiAlbums.SEO.Img.localFile.publicURL
            : data.strapiAlbums.Cover.localFile.publicURL
        }
      />
      <StyledMain>
        <MainWrapper>
          <Header>
            <Lines leftMargin leftCenter right rightMargin />
            <CoverCol>
              <BackLink>wszystkie płyty</BackLink>
              <AlbumName>{data.strapiAlbums.Name}</AlbumName>
              <SmallTitle>
                {data.strapiAlbums.artists.map((artist, index) => (
                  <span>
                    {index > 0 ? ' & ' : ''}
                    {artist.Name}
                  </span>
                ))}
              </SmallTitle>
              <StyledImg
                fluid={data.strapiAlbums.Cover.localFile.childImageSharp.fluid}
              />
              <BackLink>powrót</BackLink>
            </CoverCol>
            <MainCol>
              <AlbumName>{data.strapiAlbums.Name}</AlbumName>
              <SmallTitle>
                {data.strapiAlbums.artists.map((artist, index) => (
                  <span>
                    {index > 0 ? ' & ' : ''}
                    {artist.Name}
                  </span>
                ))}
              </SmallTitle>
              <InformationColumn>
                {data.strapiAlbums.Gold.Display ||
                data.strapiAlbums.Platinum.Display ||
                data.strapiAlbums.Diamond.Display ? (
                  <AwwardsWrapper>
                    {data.strapiAlbums.Gold.Display ? (
                      <AwardWrapper>
                        <img src={gold} alt="złota płyta" />
                        <span>
                          {data.strapiAlbums.Gold.Number > 1
                            ? data.strapiAlbums.Gold.Number + ' x '
                            : ''}{' '}
                          Złota
                        </span>
                      </AwardWrapper>
                    ) : null}
                    {data.strapiAlbums.Platinum.Display ? (
                      <AwardWrapper>
                        <img src={platinum} alt="platynowa płyta" />
                        <span>
                          {data.strapiAlbums.Platinum.Number > 1
                            ? data.strapiAlbums.Platinum.Number + ' x '
                            : ''}{' '}
                          Platynowa
                        </span>
                      </AwardWrapper>
                    ) : null}
                    {data.strapiAlbums.Diamond.Display ? (
                      <AwardWrapper>
                        <img src={diamond} alt="diamentowa płyta" />
                        <span>
                          {data.strapiAlbums.Diamond.Number > 1
                            ? data.strapiAlbums.Diamond.Number + ' x '
                            : ''}{' '}
                          Diamentowa
                        </span>
                      </AwardWrapper>
                    ) : null}
                    <DateOfUpdate>
                      Data aktualizacji: <br />{' '}
                      {data.strapiAlbums.Date_of_update}
                    </DateOfUpdate>
                  </AwwardsWrapper>
                ) : null}

                <InformationTitle>Usługi:</InformationTitle>
                {data.strapiAlbums.Services.List.map(Service => (
                  <InformationServicesPos>
                    {Service.Name} <span>/</span>
                  </InformationServicesPos>
                ))}
                <InformationRow>
                  {data.strapiAlbums.Mastering_[0] ? (
                    <InformationTitle>Mastering:</InformationTitle>
                  ) : null}

                  {data.strapiAlbums.Mastering_.map(Person => (
                    <InformationLink
                      to={`/team/${slugify(Person.Name, {
                        lower: true,
                        strict: true,
                      })}`}
                    >
                      {Person.Name} <span>/</span>
                    </InformationLink>
                  ))}
                </InformationRow>
                <InformationRow>
                  {data.strapiAlbums.Mix_[0] ? (
                    <InformationTitle>Mix:</InformationTitle>
                  ) : null}
                  {data.strapiAlbums.Mix_.map(Person => (
                    <InformationLink
                      to={`/team/${slugify(Person.Name, {
                        lower: true,
                        strict: true,
                      })}`}
                    >
                      {Person.Name} <span>/</span>
                    </InformationLink>
                  ))}
                </InformationRow>
                <InformationRow>
                  {data.strapiAlbums.Realizacja_[0] ? (
                    <InformationTitle>Realizacja:</InformationTitle>
                  ) : null}
                  {data.strapiAlbums.Realizacja_.map(Person => (
                    <InformationLink
                      to={`/team/${slugify(Person.Name, {
                        lower: true,
                        strict: true,
                      })}`}
                    >
                      {Person.Name} <span>/</span>
                    </InformationLink>
                  ))}
                </InformationRow>
                {data.strapiAlbums.Spotify}
              </InformationColumn>
              {data.strapiAlbums.Spotify ? (
                <StyledIframe
                  src={`${data.strapiAlbums.Spotify}`}
                  width="100%"
                  height="380"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                ></StyledIframe>
              ) : null}
              {data.strapiAlbums.Youtube.map(src => (
                <VideoWrapper>
                  {' '}
                  <iframe
                    width="100%"
                    height="315"
                    src={src.src}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </VideoWrapper>
              ))}
            </MainCol>
            {data.strapiAlbums.Spotify && data.strapiAlbums.Youtube ? (
              <SideCol ref={sideCol}>
                <SideColWrap>
                  <ArrowWrapper>
                    <Arrow src={arrow} className={onBottom && 'bottom'} />
                  </ArrowWrapper>
                  <SideColTextWrapp>
                    <p className={onBottom && 'bottom'}>
                      scrolluj dalej jest tego więcej
                    </p>
                    <p className={onBottom && 'bottom'}></p>
                  </SideColTextWrapp>
                </SideColWrap>
              </SideCol>
            ) : null}
          </Header>
        </MainWrapper>
      </StyledMain>
    </>
  )
}
export const query = graphql`
  query AlbumsLayout($id: String!) {
    strapiAlbums(id: { eq: $id }) {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      Cover {
        localFile {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 600
              quality: 100
              traceSVG: { background: "#000000", color: "#FF3D00" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      Name
      artists {
        Name
      }
      Services {
        List {
          Name
        }
      }
      Mastering_ {
        Name
      }
      Mix_ {
        Name
      }
      Realizacja_ {
        Name
      }
      Spotify
      Date_of_update(formatString: "DD.MM.YYYY")
      Gold {
        Display
        Number
      }
      Diamond {
        Display
        Nymber
      }
      Platinum {
        Display
        Number
      }
      Youtube {
        src
      }
    }
  }
`

export default RecruitmentLayout
