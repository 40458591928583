import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import backArrow from 'src/assets/svg/back-arrow.svg'
import { Link } from 'gatsby'

const BackBtn = styled.button`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  font-weight: 700;
  text-decoration: underline;
  align-self: flex-start;
  z-index: 5;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 20rem;
  img {
    margin-right: 10rem;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    img {
      transform: translateX(-10rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

const BackLink = styled(Link)`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  font-weight: 700;
  text-decoration: underline;
  align-self: flex-start;
  z-index: 5;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 20rem;
  img {
    margin-right: 10rem;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    img {
      transform: translateX(-10rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

const GoBack = ({ children, back, to }) => {
  return (
    <>
      {to ? (
        <BackLink to={to}>
          {' '}
          <img src={backArrow} alt="arrow" /> {children}
        </BackLink>
      ) : (
        <BackBtn onClick={() => navigate(-1)}>
          {' '}
          <img src={backArrow} alt="arrow" /> {children}
        </BackBtn>
      )}
    </>
  )
}

export default GoBack
